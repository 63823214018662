<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            DAFTAR SUB CUSTOMER
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <v-btn
                @click="addZone"
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px;"
                :disabled="loading"
              >
                + Tambah Data
              </v-btn>
              <div
                style="width: 275px; display: flex; flex-direction: row; margin-left: auto;margin-right:15px; "
              >
                <v-text-field
                  v-model="paramApi.keyword"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  label="Cari"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  style="position: relative; height:40px;"
                ></v-text-field>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 120px; display: flex; flex-direction: row;">
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Delete' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: auto;margin-right:15px; "
              >
                <v-select
                  v-model="paramApi.customer_id"
                  :items="dropdown.customer"
                  @change="customerWatcher"
                  style="height:40px;"
                  label="Customer"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer;"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
            <template v-slot:[`item.cordinate`]="{ item }">
              <div
                style="font-size:12px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.lat }},{{ item.lng }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="addDialog" width="500" persistent>
      <v-form
        v-if="form !== null"
        :disabled="loading || (form.act === 'update' && !isEdit)"
        lazy-validation
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Tambah / Edit
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top:10px;"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
              >
                <div class="d-flex" style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <div style="margin-left:auto;" v-if="form.act === 'update'">
                      <a
                        v-if="!isEdit"
                        href=""
                        @click.prevent="isEdit = true"
                        style="font-size:14px;"
                        class="d-flex flex-row"
                      >
                        <v-icon small color="#1976d2" style="margin-right:3px;">
                          mdi-application-edit-outline
                        </v-icon>
                        Edit data
                      </a>
                      <p
                        v-else
                        @click.prevent="isEdit = false"
                        style="font-size:14px; margin:0; color:red; cursor: pointer;"
                        class="d-flex flex-row"
                      >
                        Batal
                        <v-icon small color="red" style="margin-left:3px;">
                          mdi-close
                        </v-icon>
                      </p>
                    </div>
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Customer
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.customer"
                      :items="dropdown.customer"
                      :rules="rules.customerRules"
                      label="Pilih"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.name"
                      :rules="rules.nameRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <!-- <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Code
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.code"
                      :rules="rules.codeRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col> -->
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Email
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="form.email" dense outlined />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      No. Tlp
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.phone"
                      :rules="rules.phoneRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kordinat
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-row no-gutters>
                      <v-col cols="6" style="padding-right:5px;">
                        <v-text-field
                          label="lat"
                          v-model="form.lat"
                          :rules="rules.latRules"
                          dense
                          outlined
                      /></v-col>
                      <v-col cols="6" style="padding-left:5px;" class="d-flex">
                        <v-text-field
                          label="lng"
                          v-model="form.lng"
                          :rules="rules.lngRules"
                          dense
                          outlined
                        />

                        <v-btn
                          icon
                          @click="checkAddress"
                          x-small
                          color="primary"
                          text
                          outlined
                          type="button"
                          style="margin-left:5px; position:relative; top:10px;"
                          :disabled="
                            form === null ||
                              form.lat === '' ||
                              form.lng === '' ||
                              form.lat === null ||
                              form.lng === null
                          "
                        >
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kota
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.city"
                      :rules="rules.cityRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Provinsi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.province"
                      :rules="rules.provinceRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kode Pos
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.zip_code"
                      :rules="rules.zipCodeRules"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-textarea
                      dense
                      auto-grow
                      rows="2"
                      outlined
                      v-model="form.address"
                      :rules="rules.addressRules"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Odoo ID
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="form.odoo_id" dense outlined />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="closeDialog"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="form.act === 'update' && !isEdit"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script src="http://maps.googleapis.com/maps/api/js?sensor=false"></script>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'mssubcustomer',
  components: {},
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      orderBy: 'name',
      orderType: 'asc',
      customer_id: null
    },
    addDialog: false,
    form: null,
    isEdit: false,
    options: {},

    headers: [
      {
        text: 'Code',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Odoo ID',
        value: 'odoo_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kordinat (lat,lng)',
        value: 'cordinate',
        align: 'left',
        sortable: false
      },
      {
        text: 'Customer',
        value: 'customer.name',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      customer: []
    },
    rules: {
      nameRules: [],
      customerRules: [],
      zipCodeRules: [],
      phoneRules: [],
      cityRules: [],
      provinceRules: [],
      addressRules: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.initDropdown()
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
      this.initDropdown()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}sub_customer/list?keyword=${
        this.paramApi.keyword
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }`

      const customer_id = `&customer=${
        this.paramApi.customer_id !== null ? this.paramApi.customer_id : ''
      }`
      url = url + customer_id
      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async initDropdown() {
      this.dropdownCustomer()
    },
    async customerWatcher(v) {
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async dropdownCustomer() {
      this.dropdown.customer = []
      await axios
        .get(`${this.expedition}customer/dropdown?keyword=`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.customer = arr)
          }
          return (this.dropdown.customer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.customer = [])
        })
    },
    rowClick(pItem) {
      this.setForm(pItem)

      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    addZone() {
      this.setForm()
      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    submit() {
      this.rules = {
        nameRules: [v => !!v || 'Name is required'],
        customerRules: [v => !!v || 'Customer is required'],
        cityRules: [v => !!v || 'City is required'],
        provinceRules: [v => !!v || 'Province is required'],
        zipCodeRules: [v => !!v || 'Zip Code is required'],
        phoneRules: [v => !!v || 'Phone is required'],
        addressRules: [v => !!v || 'Address is required']
      }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        customer_id: this.form.customer.id,
        name: this.form.name,
        // code: this.form.code,
        phone: this.form.phone,
        email: this.form.email,
        address: this.form.address,
        lat: this.form.lat,
        lng: this.form.lng,
        city: this.form.city,
        province: this.form.province,
        zip_code: this.form.zip_code,
        odoo_id: this.form.odoo_id
      }
      console.log(form)
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      // if (this.form.act === 'add') {
      //   Object.assign(form, {
      //     id: Math.floor(Math.random() * 1000000)
      //   })
      // }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}sub_customer/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            const self = this
            setTimeout(() => {
              self.closeDialog()
              this.getDataFromApi()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    closeDialog() {
      this.addDialog = false
      this.$refs.entryForm.reset()
      this.form = null
    },
    async deleteItem() {
      this.showMsgDialog(
        'question',
        'Anda yakin ingin menghapus data ini sekarang',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.expedition}sub_customer/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.setAction()
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch(err => {
              console.log(err)
              this.showMsgDialog('error', err, false)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    setForm(xData) {
      console.log(xData)
      const x = xData !== undefined
      this.form = {
        act: x ? 'update' : 'add',
        customer: x ? xData.customer : null,
        name: x ? xData.name : '',
        email: x ? xData.email : null,
        // code: x ? xData.code : null,
        phone: x ? xData.phone : null,
        address: x ? xData.address : null,
        lat: x ? xData.lat : null,
        lng: x ? xData.lng : null,
        city: x ? xData.city : null,
        province: x ? xData.province : null,
        zip_code: x ? xData.zip_code : null,
        odoo_id: x ? xData.odoo_id : null
      }
      if (x) {
        Object.assign(this.form, { id: xData.id })
      }
    },

    async checkAddress() {
      const geocoder = new google.maps.Geocoder()
      const latlng = {
        lat: parseFloat(this.form.lat),
        lng: parseFloat(this.form.lng)
      }

      let formatted_address = '-'
      let city = '-'
      let province = '-'
      let country = '-'
      let zip_code = '-'
      let getAddress = await geocoder
        .geocode({ location: latlng })
        .then(res => {
          console.log(res.results)
          if (res.results.length > 0) {
            let xResults = null

            if (res.results.length === 1) {
              xResults = res.results[0]
            }
            if (res.results.length > 1) {
              xResults = res.results[1]
            }
            formatted_address = xResults.formatted_address
            const address_components = xResults.address_components

            if (address_components.length > 0) {
              city = address_components[address_components.length - 4].long_name
              province =
                address_components[address_components.length - 3].long_name
              country =
                address_components[address_components.length - 2].long_name
              zip_code =
                address_components[address_components.length - 1].long_name
            }
          }
          return '00'
        })
        .catch(e => {
          this.showMsgDialog('error', 'Geocoder failed due to: ' + e, false)
          return '-99'
        })
      this.form.city = city
      this.form.province = province
      this.form.zip_code = zip_code
      this.form.address = formatted_address
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
